import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'
import '../../assets/scripts/jquery.waypoints.min.js'
import '../../assets/scripts/jquery.countup.min.js'

$('.counter').countUp();


$('.mob-down-icon').on('click',function(){
  $("html, body").animate({
    scrollTop: $('.section1').offset().top - 60
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})

//判断视频是否播放
// $(document).ready(function(){
//   var $video = $('.index-banner video')
//   $video.each(function(index){
//     this.addEventListener('play', function () { //播放开始执行的函数
//       $(this).closest('.video').show()
//     });
//     this.addEventListener('pause', function () { //暂停开始执行的函数
//       $(this).closest('.video').hide()
//     });
//   })
// })

var indexBannerSwiper = new Swiper(".index-banner .mySwiper", {
  speed: 800,
  effect: 'fade',
  autoplay: {
    delay: 5000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  pagination: {
    el: ".index-banner .swiper-pagination",
    clickable: true
  },
  on: {
    init: function(swiper){
      $('.index-banner .mySwiper .swiper-wrapper .swiper-slide').eq(this.activeIndex).find('video').prop('muted', false)
      $('.index-banner .mySwiper .swiper-wrapper .swiper-slide').eq(this.activeIndex).siblings('.swiper-slide').find('video').prop('muted', true)
      $('.index-banner .swiper-cont .swiper-t .item').eq(this.activeIndex).addClass('active')
      console.log(this.slides.length)
      let total = this.slides.length > 9 ? this.slides.length : '0'+ this.slides.length
      let current = this.activeIndex+1 > 9 ? this.activeIndex+1 : '0' + (this.activeIndex+1)
      $('.index-banner .swiper-numpage .total').html(total)
      $('.index-banner .swiper-numpage .active-num').html(current)
    },
    slideChangeTransitionStart: function(){
      $('.index-banner .mySwiper .swiper-wrapper .swiper-slide').eq(this.activeIndex).find('video').prop('muted', false)
      $('.index-banner .mySwiper .swiper-wrapper .swiper-slide').eq(this.activeIndex).siblings('.swiper-slide').find('video').prop('muted', true)
      $('.index-banner .swiper-cont .swiper-t .item').eq(this.activeIndex).addClass('active').siblings().removeClass('active')
      let current = this.activeIndex+1 > 9 ? this.activeIndex+1 : '0' + (this.activeIndex+1)
      $('.index-banner .swiper-numpage .active-num').html(current)
    },
  },
});


var proImgSwiper2 = new Swiper(".section1 .swiper-text .mySwiper", {
  speed: 800,
  resizeObserver: true,
  direction: "vertical",
  allowTouchMove: false,
  on: {
    resize: function(){
      let that = this
      setTimeout(function(){
        that.update();
      },500)
    },
  },
});
var proImgSwiper1 = new Swiper(".section1 .swiper-img .mySwiper", {
  speed: 800,
  resizeObserver: true,
  navigation: {
    nextEl: ".section1 .swiper-text .swiper-button-next",
    prevEl: ".section1 .swiper-text .swiper-button-prev",
  },
  on: {
    init: function(swiper){
      $('.section1 .swiper-tab .item').eq(this.activeIndex).addClass('active')
    },
    slideChangeTransitionStart: function(){
      $('.section1 .swiper-tab .item').eq(this.activeIndex).addClass('active').siblings().removeClass('active')
      proImgSwiper2.slideTo(this.activeIndex)
    },
    resize: function(){
      let that = this
      setTimeout(function(){
        that.update();
      },500)
    },
  },
});

$('.section1 .swiper-tab .item').on('click',function(){
  let index = $(this).index()
  proImgSwiper1.slideTo(index)
})

// $(window).resize(function(){
//   setTimeout(function(){
//     proImgSwiper2.update()
//   },500)
// })


//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  let sec1Img = $('.section1 .swiper-img').offset().top - viewH + 100

  if (scroH > sec1Img) {
    $('.section1 .swiper-img .white-mask').addClass('hide')
  }
});